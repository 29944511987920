import { Container, Row, Col } from "reactstrap";
import { useSelector } from 'react-redux';
import backgroundCover from '../../assets/img/theme/profile-cover.png';
import React from 'react';

import './styles.css';

const UserHeader = () => {
  const { estabelecimento } = useSelector(state => state.estabelecimento);
  return (
      <div className="user__header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
        <img src={backgroundCover}/>
        <span className="mask" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10" className="offset-2 p-0">
              <h1 className="display-2 text-white">Olá, {estabelecimento?.nomeFantasia}</h1>
              <p className="text-white mt-0 mb-5">
                Esta é a sua página de perfil. Mantenha sempre atualizada e com as informações completas.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
  );
};

export default UserHeader;
