import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import date from 'date-and-time';
import InputMask from 'react-input-mask';

import 'react-credit-cards/es/styles-compiled.css';
import Logostripe from '../../assets/img/stripe.png'

import {
  Drawer,
  Message,
  Button as RButton,
  Modal,
  Icon
} from 'rsuite';

import { notification } from '../../services/rsuite';
import api from '../../services/api';

import {
  Button,
  Spinner,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";


const Pagamentos = () =>{
  const [current, setCurrent] = useState({});

  const [atendenteForm, setAtendenteForm] = useState({});
  const [atendentes, setAtendentes] = useState([]);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth() + 1)


  useEffect(() =>{
    const fetch = async () =>{
      const { data } = await api.get(`/estabelecimento/beneficio/${ano}/${mes}`);
      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data?.message,
        });
        return false;
      }
      setCurrent(data)
    }

    fetch()
  }, [ano, mes]);

  const onAddAtendente = async (e) =>{
    e.preventDefault()
    setAtendenteForm(prevState => ({...prevState, saving: true}))

    const { data } = await api.post(`/atendente`, atendenteForm?.credentials);

    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      setAtendenteForm(prevState => ({...prevState, saving: false}))
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto!', description: data.message});
    setAtendenteForm({saving: false})
  }

  const onEditAtendente = async (e) =>{
    e.preventDefault()
    setAtendenteForm(prevState => ({...prevState, editing: true}))

    const { data } = await api.put(`/atendente/${atendenteForm?.components?.editing}`, atendenteForm?.credentials);

    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      setAtendenteForm(prevState => ({...prevState, components:{...prevState.components}, saving: false}))
      return false;
    }
    notification('success', { placement: 'topStart',title: 'Pronto!', description: "Credenciais editadas"});
    setAtendenteForm({saving: false})
  }


  const onDeleteAtendente = async (e) =>{
    e.preventDefault()

    setAtendenteForm(prevState => ({...prevState, saving: true}))

    const { data } = await api.delete(`/atendente/${atendenteForm?.components?.deleting}`);

    if (data.error) {
      notification('error', {
        placement: 'topStart',
        title: 'Ops...',
        description: data.message,
      });
      setAtendenteForm({saving: false})
      return false;
    }
    notification('success', {
      placement: 'topStart',
      title: 'Pronto!',
      description: data.message,
    });

    setAtendenteForm({saving: false})
  }

  useEffect(() =>{
    const fetch = async () =>{
      if(atendenteForm?.searching) return;      
      setAtendenteForm(prevState => ({...prevState, searching: true}))

      const { data } = await api.get(`/atendente/lista`);

      if (data.error) {
        notification('error', {
          placement: 'topStart',
          title: 'Ops...',
          description: data.message,
        });
        setAtendenteForm(prevState => ({...prevState, searching: false}))
        return false;
      }
      setAtendentes(data?.atendentes) 
      setAtendenteForm(prevState => ({...prevState, searching: false}))
    }  
    fetch()
  }, [atendenteForm.saving])


  return(
    <>
      <Drawer
        show={atendenteForm?.components?.add}
        size="sm"
        onHide={() => {setAtendenteForm({});}}
      >
      <Drawer.Body>
        <form className="form-group col-12 mt-3" onSubmit={atendenteForm.saving ? (e) => e.preventDefault() : (e) => onAddAtendente(e)}>
          <FormGroup className="mb-3">
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Nome do usuário
            </label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <input
                placeholder="Nome do usuário"
                value={atendenteForm?.credentials?.usuario}
                className="form-control"
                onChange={(e) => setAtendenteForm(prevState => ({...prevState, credentials: {...prevState?.credentials, usuario: e.target.value}}))}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Crie uma senha
            </label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <input
                placeholder="Crie uma senha"
                type="password"
                autoComplete="password"
                className="form-control"
                value={atendenteForm?.credentials?.senha}
                onChange={(e) => setAtendenteForm(prevState => ({...prevState, credentials: {...prevState?.credentials, senha: e.target.value}}))}
              />
            </InputGroup>
          </FormGroup>
          <div>
            {
              atendenteForm.saving ? (
                <Button className="col-12" color="primary">
                  <Spinner size="sm" />
                </Button>
              ) : (
                <Button className="col-12" color="primary" type="submit">
                  Cadastrar atendente
                </Button>
              )
            }
          </div>
        </form>
        </Drawer.Body>
      </Drawer>
      <Drawer
        show={atendenteForm?.components?.editing}
        size="sm"
        onHide={() => {setAtendenteForm({});}}
      >
      <Drawer.Body>
        <form className="form-group col-12 mt-3" onSubmit={atendenteForm.saving ? (e) => e.preventDefault() : (e) => onEditAtendente(e)}>
          <FormGroup className="mb-3">
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Nome do usuário
            </label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <input
                placeholder="Usuário"
                value={atendenteForm?.credentials?.usuario}
                className="form-control"
                onChange={(e) => setAtendenteForm(prevState => ({...prevState, credentials: {...prevState?.credentials, usuario: e.target.value}}))}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Editar senha
            </label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <input
                placeholder="(inalterada)"
                type="password"
                autoComplete="password"
                className="form-control"
                value={atendenteForm?.credentials?.senha}
                onChange={(e) => setAtendenteForm(prevState => ({...prevState, credentials: {...prevState?.credentials, senha: e.target.value}}))}
              />
            </InputGroup>
          </FormGroup>
          <div>
            {
              atendenteForm.saving ? (
                <Button className="col-12" color="primary">
                  <Spinner size="sm" />
                </Button>
              ) : (
                <Button className="col-12" color="primary" type="submit">
                  Editar credenciais
                </Button>
              )
            }
          </div>
        </form>
        </Drawer.Body>
      </Drawer>
      <Modal
        show={atendenteForm?.components?.deleting}
        onHide={() => setAtendenteForm({})}
        size="xs"
      >
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
          {'  '} Tem certeza que deseja excluir? Essa ação será irreversível!
        </Modal.Body>
        <Modal.Footer>
          <RButton loading={atendenteForm.saving} onClick={(e) => onDeleteAtendente(e)} color="red">
            Sim, tenho certeza!
          </RButton>
          <RButton
            onClick={() => setAtendenteForm({})}
            appearance="subtle"
          >
            Cancelar
          </RButton>
        </Modal.Footer>
      </Modal>
      <div className="col p-5 overflow-inherit" style={{minHeight: '100vh'}}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h2 className="mb-4 mt-4 text-muted">Dr. Clube Benefícios</h2>
          <div className="d-flex">
            <div>
              <select value={mes} onChange={(e) => setMes(e.target.value)} className="form-control-alternative form-control">
                <option value="0">Todos os meses</option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </select>
            </div>
            <div className="ml-3">
              <select value={ano} onChange={(e) => setAno(e.target.value)} className="form-control-alternative form-control">
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                                <div className="text-sm font-weight-bold text-primary text-uppercase mb-3">
                                  Clientes
                                </div>
                            <div className="h4 mb-0 font-weight-bold text-gray-800">{current?.clientes || 0}</div>
                          </div>
                          <div className="col-auto">
                              <i className="fas fa-sharp fa-users fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                              <div className="text-sm font-weight-bold text-success text-uppercase mb-3">
                                  Em dia
                                </div>
                              <div className="h4 mb-0 font-weight-bold text-gray-800">{current?.emDia || 0}</div>
                          </div>
                          <div className="col-auto">
                              <i className="fas fa-sharp fa-check fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div> 
              </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
            <div className="card border-bottom-warning shadow py-2">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col mr-2">
                    <div className="text-sm font-weight-bold text-danger text-uppercase mb-3">Cancelados</div>
                    <div className="h4 mb-0 font-weight-bold text-gray-800">{current?.cancelados || 0}</div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-ban fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4" style={{ animation: 'fadeIn 1s forwards' }}>
              <div className="card border-bottom-warning shadow py-2">
                  <div className="card-body">
                      <div className="row align-items-center">
                          <div className="col mr-2">
                              <div className="text-sm font-weight-bold text-info text-uppercase mb-3">
                                Ganhos do mês
                              </div>
                              <div className="h4 mb-0 font-weight-bold text-gray-800">
                                R$ {(current?.ganhos || 0)?.toFixed(2)}
                              </div>
                          </div>
                          <div className="col-auto">
                          <i className="fas fa-money-bill fa-2x text-gray-300"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          <div className="d-sm-flex align-items-center justify-content-between my-5 pt-5">
            <h2 className="mb-4 text-muted">Atendentes</h2>
            <div>
              <Button 
                color="primary" 
                type="button"
                onClick={() => setAtendenteForm({components: {add: true}})}
              >
                Criar Atentende
              </Button>
              <br></br>
            </div>
          </div>
            <div className="">
              {
                atendentes
                ?.map(e => (
                  <div 
                    className="row p-2 pr-3 border-bottom"
                  >
                    <div className="pl-3">
                      <h4 className="h3 text-dark">Nome de usuário: {e?.usuario}</h4>
                      <h4 className="h4 text-dark">Data de criação: {date.format(new Date(e?.dataCadastro), 'DD/MM/YY')}</h4>
                    </div>
                    <div className="ml-auto">
                      {
                        atendenteForm.loading || atendenteForm.saving ? (
                          <button className="btn btn-primary py-2 px-3">
                            <Spinner size="sm"/>
                          </button>
                        ):(
                          <button className="btn py-2 px-3" style={{
                            backgroundColor: '#5DADE2',
                            color:'#FFF',
                            
                          }}
                          onClick={() => setAtendenteForm({components: {editing: e?._id}, credentials: {usuario: e.usuario}})}>
                            Alterar credenciais
                          </button>
                        )
                      }
                    </div>
                    <div className="mr-5 ml-2">
                      {
                          atendenteForm?.loading || atendenteForm.saving ? (
                          <button className="btn btn-danger py-2 px-3">
                            <Spinner size="sm"/>
                          </button>
                        ):(
                          <button className="btn py-2 px-3" 
                          style={{
                            backgroundColor: '#EC7063',
                            color:'#FFF',
                            
                          }}
                          onClick={() => setAtendenteForm({components: {deleting: e?._id}})}>
                            Excluir
                          </button>
                        )
                      }
                    </div>
                  </div>
                ))
              }
              {
                atendentes?.length === 0 && <div className='d-flex align-items-center justify-content-center px-5' style={{marginTop: '100px'}}> <p className="text-center px-5 w-75">Você não tem atendentes criados.</p></div>
              }
            </div>
        </div>
    </>
  );
}

export default Pagamentos;