import 'animate.css'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet-async'
import { Badge, Button, Card, Col, Container, Nav, NavItem, NavLink, Navbar, NavbarBrand, Row, Collapse, CardBody } from 'reactstrap'
import IlstratorPhone1 from "../../assets/img/illustrations/mobile.png"
import IlstratorPhone2 from "../../assets/img/illustrations/phone1.png"
import Ilstratorsaas from "../../assets/img/illustrations/saas.png"
import IlstratorPhone3 from "../../assets/img/illustrations/phone2.png"
import AdoptScript from '../../services/AdoptScript'


import Logodoutor from '../../assets/img/logodrclube.png'


import company1 from '../../assets/img/pexellogo.png'
import company2 from '../../assets/img/logoaws.png'
import company3 from '../../assets/img/logostripe.png'
import company4 from '../../assets/img/interconlogo.png'
import company5 from '../../assets/img/mongologo.png'
import perfilpage from '../../assets/img/illustrations/perfilpage.png'
import perfilservicos from '../../assets/img/illustrations/perfilservicos.png'
import comunicacaocliente from '../../assets/img/illustrations/comunicacaocliente.png'
import contaicon from '../../assets/img/illustrations/contaicon.png'
import configuraricon from '../../assets/img/illustrations/configuraricon.png'
import clienteicon from '../../assets/img/illustrations/clienteicon.png'
import visibilidadeicon from '../../assets/img/illustrations/visibilidadeicon.png'
import servicosicon from '../../assets/img/illustrations/servicosicon.png'
import agendamentoicon from '../../assets/img/illustrations/agendamentoicon.png'
import contatoicon from '../../assets/img/illustrations/contatoicon.png'
import filialicon from '../../assets/img/illustrations/filialicon.png'
import convenioicon from '../../assets/img/illustrations/convenioicon.png'
import beneficiosicon from '../../assets/img/illustrations/beneficiosicon.png'
import promocaoicon from '../../assets/img/illustrations/promocaoicon.png'
import dasboardicon from '../../assets/img/illustrations/dasboardicon.png'
import appStore from '../../assets/img/illustrations/app-store.png'
import divulgacao_ampliada from '../../assets/img/illustrations/divulgacao_ampliada.png'
import Tecnologia_Inovadora from '../../assets/img/illustrations/Tecnologia_Inovadora.png'
import Comunicação_Direta from '../../assets/img/illustrations/Comunicação_Direta.png'
import Acessibilidade from '../../assets/img/illustrations/Acessibilidade.png'
import Cartão from '../../assets/img/illustrations/Cartão.png'
import servicos_app from '../../assets/img/illustrations/servicos_app.png'
import app_gps from '../../assets/img/illustrations/app_gps.png'
import app from '../../assets/img/illustrations/app.png'
import arrowRight from '../../assets/img/illustrations/arrow-right.png'
import automatic from '../../assets/img/illustrations/automatic.png'
import callToAction from '../../assets/img/illustrations/call-to-action.png'
import cloud from '../../assets/img/illustrations/cloud.png'
import cta from '../../assets/img/illustrations/cta.png'
import customization from '../../assets/img/illustrations/customization.png'
import fastPerformance from '../../assets/img/illustrations/fast-performance.png'
import featureBg from '../../assets/img/illustrations/feature-bg.png'
import googlePlay from '../../assets/img/illustrations/google-play.png'
import heroBackground from '../../assets/img/illustrations/hero-bg.png'
import network from '../../assets/img/illustrations/network.png'
import prototype from '../../assets/img/illustrations/prototype.png'
import rewards from '../../assets/img/illustrations/rewards.png'
import support from '../../assets/img/illustrations/support.png'
import timeWard from '../../assets/img/illustrations/time-award.png'
import ultimateFeature from '../../assets/img/illustrations/ultimate-feature.png'
import ux from '../../assets/img/illustrations/ux.png'
import vector from '../../assets/img/illustrations/vector.png'
import { Link } from 'react-router-dom'

import './theme.css';
//Set your APP_ID
var APP_ID = "APP_ID";

window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "iu1wjw4c"
};

(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();


// Google Analytics
ReactGA.initialize('G-FXC6TDP80Z');




export default () =>{
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

      const [activeAccordion, setActiveAccordion] = useState(null);

      const handleAccordionToggle = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
      };
      const [isOpen, setIsOpen] = useState(-1);

  const toggleAccordion = (index) => {
    setIsOpen((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return(
    
    <>
        <Helmet>
            <title>Dr. Clube - Seja Parceiro</title>
            <meta name="description" content="Seja encontrado por milhares de clientes" />
            <link rel='canonical' href='/' />
        </Helmet>
       
        <section className="py-0" id="home" style={{ backgroundImage: `url(${heroBackground})`, backgroundPosition: "bottom", backgroundSize: "cover" }}>
            <Container className="position-relative">
                <Row className="align-items-center py-8">
                <Col md={{ size: 5, order: 1 }} lg={5} className="text-center text-md-end">
                    <img className="img-fluid" src={Ilstratorsaas} width="410" alt="" />
                </Col>
                <Col md={7} lg={6} className="text-center text-md-start">
                    <Badge color="light" className="rounded-pill text-dark align-items-center d-flex flex-row-reverse justify-content-end mx-auto mx-md-0 ps-0 w-75 w-sm-50 w-md-75 w-xl-50 mb-3">
                    #1 Editiors Choice App of 2023
                    <img className="img-fluid float-start me-3" src={arrowRight} alt="" />
                    </Badge>
                    <h1 className="mb-4 display-3 fw-bold lh-sm">Seja parceiro na maior plataforma de serviços<br className="d-block d-lg-none d-xl-block" /> do Brasil.</h1>
                    <p className="mt-3 mb-4 fs-1">Com o Dr. Clube, você conecta seus serviços a milhões de novos clientes, <br className="d-none d-lg-block" />aumente suas vendas e ganhe vantagem competitiva.</p>
                    <a href="/cadastro">
                       <button class="btn btn-lg btn-primary rounded-pill order-0" type="submit">
                         Seja parceiro
                        </button>
                    </a>
                    
                   
                </Col>
                </Row>
            </Container>
        </section>
        <section className="py-7">
            <Container>
            <Row>
                <Col xs={12} className="mx-auto align-items-center text-center">
                <p className="mb-4">Confiável por empresas como</p>
                </Col>
            </Row>
            <Row className="align-items-center justify-content-center justify-content-lg-around">
                <Col xs={6} sm={4} md={4} lg={2} className="px-md-0 mb-5 mb-lg-0 text-center"><img src={company1} alt="" /></Col>
                <Col xs={6} sm={4} md={4} lg={2} className="px-md-0 mb-5 mb-lg-0 text-center"><img src={company2} alt="" /></Col>
                <Col xs={6} sm={4} md={4} lg={2} className="px-md-0 mb-5 mb-lg-0 text-center"><img src={company3} alt="" /></Col>
                <Col xs={6} sm={4} md={4} lg={2} className="px-md-0 mb-5 mb-lg-0 text-center"><img src={company4} alt="" /></Col>
                <Col xs={6} sm={4} md={4} lg={2} className="px-md-0 mb-5 mb-lg-0 text-center"><img src={company5} alt="" /></Col>
            </Row>
            </Container>
        </section>
        <section className="py-6" id="features">
            <Container fluid="lg">
                <Row className="align-items-center">
                <Col md={{ size: 5, order: 0 }} lg={6} className="text-center text-md-start">
                    <img className="img-fluid" src={IlstratorPhone3} width="550" alt="" />
                </Col>
                <Col md={7} lg={6} sm={12} className="px-sm-5 px-md-0">
                    <h6 className="fw-bold fs-4 display-3 lh-sm">Vantagens de ser um parceiro</h6>
                    <p className="my-4">Essas são apenas algumas vantagens de se tornar um parceiro <br className="d-none d-xl-block" />em nossa plataforma de serviços.</p>
                    <div className="d-flex align-items-center mb-5">
                    <div><img className="img-fluid" src={perfilpage} width="90" alt="" /></div>
                    <div className="px-4">
                        <h5 className="fw-bold text-danger">Perfil do estabelecimento</h5>
                        <p>Tenha sua marca em destaque para milhares <br className="d-none d-xl-block" /> de usuários, aumentando sua visibilidade <br className="d-none d-xl-block" /> e alcance de forma significativa</p>
                    </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                    <div><img className="img-fluid" src={perfilservicos} width="90" alt="" /></div>
                    <div className="px-4">
                        <h5 className="fw-bold text-primary">Publicação de serviços</h5>
                        <p>Aumente a visibilidade dos seus serviços,<br className="d-none d-xl-block" /> atingindo um público mais amplo e oferecendo <br className="d-none d-xl-block" /> uma experiência exclusiva aos clientes</p>
                    </div>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                    <div><img className="img-fluid" src={comunicacaocliente} width="90" alt="" /></div>
                    <div className="px-4">
                        <h5 className="fw-bold text-success">Comunicação com o cliente</h5>
                        <p>Comunique-se rapidamente com seus clientes<br className="d-none d-xl-block" /> por meio do whatsApp e chamadas telefônicas<br className="d-none d-xl-block" /></p>
                    </div>
                    </div>
                </Col>
                </Row>
            </Container>
        </section>
        <section className="py-5">
            <Container>
                <Row className="row justify-content-center mb-8">
                <Col md={5} lg={4} offset-lg={1}>
                    <h1 className="fw-bold lh-base">Quem pode ser um parceiro?</h1>
                </Col>
                <Col md={6} lg={5} offset-lg={1} className="border-start py-5 ps-5">
                    <p className="mb-0">Clínicas Médicas e odontológicas &bull; Consultórios &bull; Centro de Estética e terapias &bull; Salões de Beleza e Barbearias &bull; Academias &bull; Serviços pet e Veterinários</p>
                </Col>
                </Row>

                <div className="row justify-content-center mb-6">
                    <div className="col-lg-6 text-center mx-auto mb-3 mb-md-5 mt-4">
                    <h6 className="fw-bold fs-4 display-3 lh-sm">Como ser um parceiro <br />Dr. Clube </h6>
                    </div>
                </div>

                <div className="row">                
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={contaicon} width="90" alt="" />
                        <h5 className="fw-bold">Crie uma conta</h5>
                        <p className="mt-2 mb-0">Pra começar, vamos precisar do nome do seu negócio, CNPJ, e-mail e logo.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={configuraricon} width="90" alt="" />
                        <h5 className="fw-bold">Configure sua loja</h5>
                        <p className="mt-2 mb-0">Preencha as informações, cadastre os serviços e estará tudo pronto para ativar sua loja.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={clienteicon} width="90" alt="" />
                        <h5 className="fw-bold">Receba os clientes</h5>
                        <p className="mt-2 mb-0">Agora o cliente pode agendar de forma rápida e fácil pelo WhatsApp e fazer ligações através do aplicativo.</p>
                    </div>
                    </div>
                </div>
            </Container>
        </section>
        <section className="py-6">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4 order-md-0 text-center text-md-start"><img className="img-fluid mb-4" src={Cartão} width="500" alt="" /></div>
                    <div className="col-md-6 text-center text-md-start offset-md-1">
                    <h6 className="fw-bold fs-4 display-3 lh-sm">Você quer oferecer mais vantagens aos seus clientes e ainda ganhar com isso?</h6>
                    <p className="my-4 pe-xl-5"> Nosso exclusivo plano de benefícios para parceiros permite que você disponibilize uma gama de vantagens incríveis para seus clientes, enquanto recebe uma parte dos valores do plano. É uma oportunidade única de agregar valor aos seus serviços, fidelizar clientes e aumentar sua receita. Descubra como nosso programa de parcerias pode impulsionar o seu negócio!</p><a className="btn btn-lg btn-primary rounded-pill hover-top" href='/cadastro' role="button">Seja parceiro</a>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-6">
            <div className="container">
                <div className="row justify-content-center mb-6">
                    <div className="col-lg-6 text-center mx-auto mb-3 mb-md-5 mt-4">
                    <h6 className="fw-bold fs-4 display-3 lh-sm">Por que você deve escolher <br />o Dr. Clube? </h6>
                   
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={visibilidadeicon} width="90" alt="" />
                        <h5 className="fw-bold">Visualização do seu negócio</h5>
                        <p className="mt-2 mb-0">Tenha sua marca em destaque para milhares de usuários, aumentando sua visibilidade e alcance de forma significativa.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={servicosicon} width="90" alt="" />
                        <h5 className="fw-bold">Publicação de serviços</h5>
                        <p className="mt-2 mb-0">Crie e personalize uma ampla variedade de serviços proporcionando uma experiência única.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={agendamentoicon} width="90" alt="" />
                        <h5 className="fw-bold">Agendamento</h5>
                        <p className="mt-2 mb-0">Ofereça a comodidade do agendamento online, permitindo que os clientes reservem seus serviços de maneira rápida e eficiente.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={contatoicon} width="90" alt="" />
                        <h5 className="fw-bold">Facilidade de Contato</h5>
                        <p className="mt-2 mb-0">Torne mais fácil para os clientes entrar em contato, fornecendo informações de contato direto e horários de funcionamento</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={convenioicon} width="90" alt="" />
                        <h5 className="fw-bold">Convênios</h5>
                        <p className="mt-2 mb-0">Disponibilize os convênios aceitos pelo seu estabelecimento e expanda o alcance do seu negócio.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={filialicon} width="90" alt="" />
                        <h5 className="fw-bold">Gerenciamento de filiais</h5>
                        <p className="mt-2 mb-0">Gerencie acesso a outras contas da mesma rede diretamente da sua conta principal.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={beneficiosicon} width="90" alt="" />
                        <h5 className="fw-bold">Descontos e benefícios</h5>
                        <p className="mt-2 mb-0">Ofereça programas de fidelidade e recompensas, proporcionando benefícios adicionais aos clientes fiéis.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={promocaoicon} width="90" alt="" />
                        <h5 className="fw-bold">Promoções temporárias atraentes</h5>
                        <p className="mt-2 mb-0">Crie promoções temporárias irresistíveis, aproveitando a urgência para impulsionar as conversões e aumentar as vendas.</p>
                    </div>
                    </div>
                    <div className="col-md-4 mb-6">
                    <div className="text-center px-lg-3"><img className="img-fluid mb-3" src={dasboardicon} width="90" alt="" />
                        <h5 className="fw-bold">Dashboard</h5>
                        <p className="mt-2 mb-0">Acompanhe o total de visualizações, cliques, origem dos agendamentos e ranking dos serviços.</p>
                    </div>
                    </div>
                </div>

            </div>
            </section>
            <section className="py-6">
                <div className="container">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5 order-md-1 text-center text-md-end">
                                <img className="img-fluid mb-4" src={servicos_app} width="500" alt="" />
                                </div>
                                <div className="col-md-6 text-center text-md-start">
                                    <h6 className="fw-bold fs-4 display-3 lh-sm">Seus serviços mais<br />perto dos clientes</h6>
                                    <p className="my-4 pe-xl-5"> Em um mundo cada vez mais conectado, a tecnologia se torna uma ferramenta essencial para o sucesso de qualquer negócio. E quando se trata de aproximar seus serviços dos clientes, as possibilidades são infinitas..</p>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                        <div className="py-4"><img className="img-fluid" src={divulgacao_ampliada} width="90" alt="" /></div>
                                        <h5 className="fw-bold text-undefined">Divulgação Ampliada</h5>
                                        <p className="mt-2 mb-0">Exponha seus serviços a um público maior e mais engajado, aproveitando a base de usuários do nosso aplicativo.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                        <div className="py-4"><img className="img-fluid" src={Tecnologia_Inovadora} width="90" alt="" /></div>
                                        <h5 className="fw-bold text-undefined">Tecnologia Inovadora</h5>
                                        <p className="mt-2 mb-0">Utilize recursos inovadores, como inteligência artificial, geolocalização e agendamento de serviços.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                        <div className="py-4"><img className="img-fluid" src={Comunicação_Direta} width="90" alt="" /></div>
                                        <h5 className="fw-bold text-undefined">Comunicação Direta</h5>
                                        <p className="mt-2 mb-0">Interaja diretamente com os clientes, tire dúvidas e construa relacionamentos duradouros.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                        <div className="py-4"><img className="img-fluid" src={Acessibilidade} width="90" alt="" /></div>
                                        <h5 className="fw-bold text-undefined">Praticidade e Acessibilidade</h5>
                                        <p className="mt-2 mb-0">O cliente pode encontrar e contratar serviços com apenas alguns cliques, de qualquer lugar e a qualquer hora.</p>
                                        </div>
                                    </div>
                                </div><a className="btn btn-lg btn-primary rounded-pill hover-top" href='/cadastro' role="button">Seja parceiro</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-8" id="pricing">
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xxl-5 text-center mb-3">
                    <h6 className="fw-bold fs-4 display-3 lh-sm mb-3">Plano automático</h6>
                    <p className="mb-4">Plano detectado automáticamente de acordo com a quantidade de serviços.
                                        Teste grátis por 30 dias.
                                        Sem fidelidade. Cancele quando quiser.</p>
                    </div>
                </div>
                <div className="row flex-center">
                    <div className="col-lg-4">
                    <div className="card shadow-lg mb-4 border-0">
                        <div className="card-header border-bottom-0 pt-7 pb-5">
                        <div className="d-flex justify-content-center">
                            <h1 className="fw-bold">Grátis</h1><span className="d-flex align-items-center"></span>
                        </div>
                        <h5 className="fw-bold text-center">Básico</h5><span className="text-700 text-center d-block">Ideal para quem oferece<br /> poucos serviços</span>
                        </div>
                        <div className="card-body mx-auto">
                        <ul className="list-unstyled mb-4">
                            <li className="text-700 py-2 text-secondary">Até 5 Serviços</li>
                            <li className="text-700 py-2 text-secondary">Crie combos ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Clientes ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Banco de imagens grátis </li>
                            <li className="text-700 py-2 text-secondary">Atenda via whatsapp </li>
                            <li className="text-700 py-2 text-secondary">Atenda via ligações </li>
                            <li className="text-700 py-2 text-secondary">Cadastro de convênios </li>
                            <li className="text-700 py-2 text-secondary">Mais recursos </li>
                        </ul>
                        <div className="d-flex flex-column"> <a className="btn btn-lg btn-primary rounded-pill mb-3" href='/cadastro'>Iniciar</a><a href="#">Grátis pra sempre</a></div>

                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="card shadow-lg mb-4">
                        <div className="card-header border-bottom-0 pt-7 pb-5">
                        <div className="d-flex justify-content-center">
                            <h1 className="fw-bold">R$33</h1><span className="d-flex align-items-center">/mês</span>
                        </div>
                        <h5 className="fw-bold text-center">Padrão</h5><span className="text-700 text-center d-block">Ideal para pequenos e médios<br /> negócios</span>
                        </div>
                        <div className="card-body mx-auto">
                        <ul className="list-unstyled mb-4">
                            <li className="text-700 py-2 text-secondary">Até 15 Serviços</li>
                            <li className="text-700 py-2 text-secondary">Crie combos ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Clientes ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Banco de imagens grátis </li>
                            <li className="text-700 py-2 text-secondary">Atenda via whatsapp </li>
                            <li className="text-700 py-2 text-secondary">Atenda via ligações </li>
                            <li className="text-700 py-2 text-secondary">Cadastro de convênios </li>
                            <li className="text-700 py-2 text-secondary">Mais recursos </li>
                        </ul>
                        <div className="d-flex flex-column"> <a className="btn btn-lg btn-primary rounded-pill mb-3" href='/cadastro'>Teste grátis</a><a href="#">Experimente por 30 dias</a></div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="card shadow-lg mb-4">
                        <div className="card-header border-bottom-0 pt-7 pb-5">
                        <div className="d-flex justify-content-center">
                            <h1 className="fw-bold">R$55</h1><span className="d-flex align-items-center">/mês</span>
                        </div>
                        <h5 className="fw-bold text-center">Premium</h5><span className="text-700 text-center d-block">Ideal para negócios com muitos <br /> serviços</span>
                        </div>
                        <div className="card-body mx-auto">
                        <ul className="list-unstyled mb-4">
                            <li className="text-700 py-2 text-secondary">Serviços Ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Crie combos ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Clientes ilimitados</li>
                            <li className="text-700 py-2 text-secondary">Banco de imagens grátis </li>
                            <li className="text-700 py-2 text-secondary">Atenda via whatsapp </li>
                            <li className="text-700 py-2 text-secondary">Atenda via ligações </li>
                            <li className="text-700 py-2 text-secondary">Cadastro de convênios </li>
                            <li className="text-700 py-2 text-secondary">Mais recursos </li>
                        </ul>
                        <div className="d-flex flex-column"> <a className="btn btn-lg btn-primary rounded-pill mb-3" href='/cadastro'>Teste grátis</a><a href="#">Experimente por 30 dias</a></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-8" id="faq">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} className="text-center mb-3">
            <h6 className="fw-bold fs-4 display-3 lh-sm mb-5">Perguntas Frequentes</h6>
          
          </Col>
        </Row>
        <Row className="flex-center">
          <Col lg={9}>
            {faqData.map((faq, index) => (
              <Card key={index} className="mb-2 border-0">
                <Button
                  color="link"
                  className="accordion-button d-flex justify-content-between align-items-center border-0"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={isOpen === index}
                  aria-controls={`faqCollapse${index}`}
                >
                  <span className="mb-0 fw-bold text-start fs-1 text-1000">{faq.question}</span>
                </Button>
                <Collapse isOpen={isOpen === index} id={`faqCollapse${index}`}>
                  <CardBody className="mb-0 text-start fs-1 text-1000">{faq.answer}</CardBody>
                </Collapse>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
        <section className="py-6">
        <hr />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-7 order-md-1 text-center text-md-start z-index-2 cta-image"><img className="img-fluid mb-4 mb-md-0" src={app_gps} width="850" alt="" /></div>
            <div className="col-md-7 col-lg-5 text-center text-md-start">
              <h1 className="display-3 fw-bold lh-sm">Download para usuários</h1>
              <p className="my-4"> Os usuários acessam a plataforma através do aplicativo móvel, onde podem encontrar serviços de saúde, agendar, deixar avaliações e muito mais, tudo de forma rápida e conveniente.</p>
              <div className="d-flex justify-content-center d-md-inline-block"><a className="pe-2 pe-sm-3 pe-md-4" href='https://play.google.com/store/apps/details?id=com.drclube'><img src={googlePlay} width="160" alt="" /></a></div>
            </div>
          </div>
        </div>
      </section>
        <AdoptScript />
    </>
  )
}

const faqData = [
    {
      question: 'O que e o Dr. Clube?',
      answer: 'Somos um marketplace de serviços que conecta provedores de serviços de saúde a um público amplo e diversificado, oferecendo uma oportunidade única para expandir sua base de clientes e impulsionar seu negócio.',
    },
    {
      question: 'Quais serviços posso oferecer como parceiro?',
      answer: 'Como parceiro, você pode oferecer uma variedade de serviços para atender às necessidades dos clientes em diversos setores. Alguns dos serviços que você pode oferecer incluem: Clínicas Médicas e Odontológicas: Consultas médicas, exames laboratoriais, procedimentos odontológicos, limpeza dental, entre outros. / Consultórios: Atendimento médico especializado, consultas de especialidades médicas, acompanhamento de saúde, entre outros serviços de saúde. / Centro de Estética e Terapias: Tratamentos faciais, massagens terapêuticas, depilação, tratamentos corporais, terapias alternativas, entre outros serviços estéticos e de bem-estar. / Salão de Beleza e Barbearias: Cortes de cabelo, coloração, tratamentos capilares, manicure, pedicure, depilação, barba, entre outros serviços de beleza e cuidados pessoais. / Academias: Aulas de fitness, treinamento personalizado, programas de condicionamento físico, aulas de grupo, acompanhamento de treino, entre outros serviços de fitness e saúde. / Serviços Pet e Veterinários: Banho e tosa, consultas veterinárias, vacinação, procedimentos cirúrgicos, pet sitting, entre outros serviços de cuidados com animais de estimação.',
    },
    {
      question: 'Como posso me tornar um parceiro de serviços no Dr. Clube?',
      answer: 'Para se tornar um parceiro de serviços no Dr. Clube, basta se cadastrar em nossa plataforma como parceiro e seguir o processo de aprovação. Depois disso, você poderá começar a oferecer seus serviços para nossa base de clientes.',
    },
    {
      question: 'Como funciona a cobrança do plano no Dr. Clube para os parceiros?',
      answer: 'No Dr. Clube, oferecemos diferentes planos de assinatura. A cobrança do plano é feita mensalmente e o valor é automaticamente do cartão de crédito cadastrado em sua conta. Você pode atualizar ou cancelar seu plano a qualquer momento através das configurações da sua conta',
    },
    {
        question: 'Como funciona o acesso à plataforma para parceiros e usuários?',
        answer: 'Na plataforma do Dr. Clube, os parceiros têm acesso à sua conta através do navegador da web, onde podem acompanhar, configurar e  gerenciar seus serviços. Por outro lado, os usuários acessam a plataforma através do aplicativo móvel, onde podem encontrar serviços de saúde, agendar, deixar avaliações e muito mais, tudo de forma rápida e conveniente.'
    },
      
  ];
  