import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import IsVisible from 'react-is-visible';

function Planos() {
  return (
    <>
    <Helmet>
     <title>Planos</title>
     <meta name="description" content="Veja todos os preços, planos e recursos do Dr. Clube. Teste grátis por 30 dias. Sem fidelidade. Cancele quando quiser. " />
     <link rel='canonical' href='/planos' />
     
  </Helmet>
    <div className="prising_area">
        <div className="container">
        <IsVisible>
            {(IsVisible) => (
            <>
            <div className="row">
                <div className="col-xl-12">
                    <div className={`section_title text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                        <h3>Plano automático</h3>
                        <p>Plano detectado automáticamente de acordo com a quantidade de serviços.</p>
                        <p>Sem fidelidade. Cancele quando quiser.</p>
                    </div>
                </div>
            </div>
            <div className="row no-gutters align-items-center">
                <div className="col-xl-4 col-md-4">
                    <div className={`single_prising text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                        <div className="prising_header d-flex justify-content-between">
                            <h5>Básico</h5>
                            <span>Grátis</span>
                        </div>
                        <ul>
                        <li>Até 5 Serviços</li>
                            <li>Crie combos ilimitados</li>
                            <li>Perfil da empresa</li>
                            <li>Cadastro de convênios</li>
                            <li>Clientes ilimitados</li>
                            <li>Banco de imagens grátis</li>
                            <li>Visualizações e cliques</li>
                            <li>Atenda via whatsapp</li>
                            <li>Atenda via ligações</li>
                            <li>Suporte Dr. Clube</li>
                        </ul>
                        <div className="prising_bottom">
                            <Link to="/cadastro" className="get_now prising_btn">Iniciar</Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-4">
                    <div className={`single_prising text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                        <div className="prising_header d-flex justify-content-between pink_header">
                            <h5>Padrão</h5>
                            <span>R$33/Mês</span>
                        </div>
                        <ul>
                        <li>Até 15 Serviços</li>
                            <li>Crie combos ilimitados</li>
                            <li>Perfil da empresa</li>
                            <li>Cadastro de convênios</li>
                            <li>Clientes ilimitados</li>
                            <li>Banco de imagens grátis</li>
                            <li>Visualizações e cliques</li>
                            <li>Atenda via whatsapp</li>
                            <li>Atenda via ligações</li>
                            <li>Suporte Dr. Clube</li>
                        </ul>
                        <div className="prising_bottom">
                            <Link to="/cadastro" className="get_now prising_btn">Teste grátis</Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-4">
                    <div className={`single_prising text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                        <div className="prising_header d-flex justify-content-between green_header">
                            <h5>Premium</h5>
                            <span>R$55/Mês</span>
                        </div>
                        <ul>
                        <li>Serviços Ilimitados</li>
                            <li>Crie combos ilimitados</li>
                            <li>Perfil da empresa</li>
                            <li>Cadastro de convênios</li>
                            <li>Clientes ilimitados</li>
                            <li>Banco de imagens grátis</li>
                            <li>Visualizações e cliques</li>
                            <li>Atenda via whatsapp</li>
                            <li>Atenda via ligações</li>
                            <li>Suporte Dr. Clube</li>
                        </ul>
                        <div className="prising_bottom">
                            <Link to="/cadastro" className="get_now prising_btn">Teste grátis</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-12">
                    <div className={`section_title text-center ${IsVisible && 'animate__animated animate__fadeInUp'}`}>
                    <p>* Teste grátis por 30 dias. Cancele quando quiser.</p>
                    </div>
                </div>
            </div>
            </div>
            </>
            )}
            </IsVisible>
        </div>
    </div>
    </>
  )
}

export default Planos;