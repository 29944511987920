import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import date from 'date-and-time';
import api from '../../services/api';

import './styles.css';

const ServicoTextos = ({id}) =>{
  const { form } = useSelector(state => state.servico);  

  const [data, setData] = useState()

  useEffect(() => {
    const fetchData = async () =>{
      const response = await api.get(`/servico/${id}`);
      setData(response?.data?.servico)
    }
    fetchData();
  }, [form.saving])

  return(
    <div>
      <h4 className="servico__titulo">{data?.titulo}</h4>
      <p className="servico__descricao">{data?.descricao}</p>
      <div className="d-flex">
        {
          data?.categoria !== 'Odonto' && (
            <div className="servico__sub__item mr-4">
              <label htmlFor="preco">Preço</label>
              <p id="preco" className="servico__preco">R$ {data?.preco?.toFixed(2)}</p>
            </div>
          )
        }
         <div className="servico__sub__item mr-4">
              <label htmlFor="preco">Preço 1 vez</label>
              <p id="preco" className="servico__preco1vez">R$ {data?.preco1vez?.toFixed(2)}</p>
            </div>
        <div className="servico__sub__item mr-2">
          <label htmlFor="data">Data da criação</label>
          <p id="data" className="servico-data">{date.format(new Date(data?.dataCadastro), 'DD/MM/YY')}</p>
        </div>
        {
          data?.categoria != "Combo" && (
            <div className="servico__sub__item mr-2">
              <label htmlFor="data">Categoria</label>
              <p id="data">{data?.categoria}</p>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ServicoTextos;
