import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import NoResponsive from '../../components/NoResponsive';

import {
  Container,
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import './styles.css';

import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cadastrar, updateEstabelecimento } from '../../store/modules/estabelecimento/actions';
import { Helmet } from 'react-helmet-async';


const Cadastro = () => {
  const schema = yup.object().shape({
    file: yup
    .mixed()
    .required("Selecione uma imagem.")
    .test("fileFormat", "Imagem inválida.", value => {
      if (!value) return true; // Permite que o campo seja vazio (não selecionado)
      const aceitos = ["image/jpeg", "image/jpg", "image/png"];

      return aceitos.includes(value[0].type);
    }),
    nomeEmpresarial: yup.string('insira um empresarial válido').required('insira um nome empresarial!').min(4, 'insira um nome com 4 dígitos ou mais!'),
    nomeFantasia: yup.string('insira um nome fantasia válido').required('insira um nome nome fantasia!').min(4, 'insira um nome com 4 dígitos ou mais!'),
    email: yup.string('insira um email válido!').required('insira um email!').email('insira um email válido!'),
    cnpj: yup.string('insira um CNPJ válido').required('insira um nome CNPJ!'),
    senha: yup.string('insira uma senha válida!').required('insira uma senha!').min(8, 'insira uma senha com 8 dígitos ou mais!'),
  })

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({
    resolver: yupResolver(schema)
  });

  var { form } = useSelector((state) => state.estabelecimento);

  const [filePreview, setFilePreview] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const setEstabelecimento = (key, value) => {
    dispatch(
      updateEstabelecimento({
        form: { ...form, [key]: value }
      })
    );
  };
  const onSubmit = async data => {
    if(  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      setIsMobile(true)
    }else{
      dispatch(
        updateEstabelecimento({
          form: { ...form, saving: true, file: data.file}
        })
      );
      dispatch(cadastrar())
    }
  }
  return (
    <>
    <Helmet>
       <title>Cadastro</title>
       <meta name="description" content="Faça o seu cadastro agora mesmo com apenas alguns cliques e aproveite todas as vantagens oferecidas pela nossa plataforma." />
       <link rel='canonical' href='/cadastro' />
       
    </Helmet>

      <Container className="mt--9 pb-5">
        <NoResponsive show={isMobile} setShow={setIsMobile}/>
        <Row className="justify-content-center">
          <Col lg="5" md="8 mx-auto h-100 mt-5">
            <Card className="bg-secondary shadow border-0 mt-5">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={form.saving ? (e) => e.preventDefault() : handleSubmit(onSubmit)}>
                <div className="col-12 mb-6 input__img">
                 <input
                     type="file"
                     className="input__img"
                     id="file"
                     name="file"
                     accept=".jpg, .jpeg, .png"
                     required
                      {...register("file", {
                        onChange: e => setFilePreview(URL.createObjectURL(e.target.files[0]))
                     })}
                 />
                    <img className="input__img__img" src={filePreview || 'https://doutor-clube-dev.s3.amazonaws.com/convenios/Avatar+Icon.png'} alt="" />
                    <p class="form-control-label-img">Baixar logo</p>
                    {
                      errors?.file?.type &&
                      <small className="Validação" style={{color: "#ff0000"}}>{errors?.file?.message}</small>
                    }
                </div>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      <span className="mr-2">Nome oficial</span>
                      <span class="custom-tooltip">
                          <i className="fas fa-question-circle fa-sm tooltip-icon" />
                            <span class="tooltip-text"> Nome oficial do seu negócio ou profissional registrado no CNPJ.</span>
                      </span>
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        placeholder="Nome oficial registrado no CNPJ"
                        type="name"
                        autoComplete="new-name"
                        className="form-control"
                        ref={register}
                        {...register("nomeEmpresarial", {
                          onChange:(e) => setEstabelecimento('nomeEmpresarial', e.target.value.trim())
                        })} 
                      />
                    </InputGroup>
                    {
                      errors.nomeEmpresarial?.type &&
                      <small className="validacao">{errors?.nomeEmpresarial?.message}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                     <span className="mr-2">Nome fantasia</span>
                          <span class="custom-tooltip">
                          <i className="fas fa-question-circle fa-sm tooltip-icon" />
                            <span class="tooltip-text"> Nome fantasia apresentado ao público.</span>
                      </span>
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-atom" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        placeholder="Nome fantasia apresentado"
                        className="form-control"
                        type="name"
                        autoComplete="new-name"
                        ref={register}
                        {...register("nomeFantasia", {
                          onChange:(e) => setEstabelecimento('nomeFantasia', e.target.value.trim())
                        })}
                      />
                    </InputGroup>
                    {
                      errors.nomeFantasia?.type &&
                      <small className="validacao">{errors?.nomeFantasia?.message}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      CNPJ
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-badge" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <CpfCnpj
                        value={form?.cnpj}
                        placeholder="Digite seu CNPJ"
                        className="form-control"
                        {...register("cnpj")}
                        onChange={(e) => {
                          setEstabelecimento('cnpj', e.target.value);
                          setValue('cnpj', e.target.value);
                          !!e.target.value && setError("cnpj", {})
                        }}
                      />
                    </InputGroup>
                    {
                      errors.cnpj?.type &&
                      <small className="validacao">{errors?.cnpj?.message}</small>
                    }
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Seu melhor e-mail
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        placeholder="Digite seu e-mail"
                        autoComplete="new-email"
                        className="form-control"
                        ref={register}
                        {...register("email", {
                          onChange:(e) => setEstabelecimento('email', e.target.value)
                        })}
                        
                      />
                    </InputGroup>
                    {
                      errors.email?.type &&
                      <small className="validacao">{errors?.email?.message}</small>
                    }
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Senha
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        placeholder="Digite uma senha"
                        type="password"
                        autoComplete="new-password"
                        className="form-control"
                        ref={register}
                        {...register("senha",{
                          onChange:(e) => setEstabelecimento('senha', e.target.value)
                        })}
                      />
                      
                    </InputGroup>
                    {
                      errors.senha?.type &&
                      <small className="validacao">{errors?.senha?.message}</small>
                    }
                  </FormGroup>
                  <Row className="my-4">
                    <Col xs="12">
                    <div>
                       <span className="text-muted">
                          Ao clicar em criar conta, você concorda com nossos{" "}
                          <a href="termo" target="_blank">
                          Termos de uso
                          </a>{" "}
                        e{" "}
                          <a href="politicaprivacidade" target="_blank">
                          Política de Privacidade
                          </a>.
                      </span>
                    </div>

                    </Col>
                  </Row>
                  <div className="text-center">
                    {
                      form.saving ? (
                        <Button className="m3-4" color="primary">
                          <Spinner size="sm" />
                        </Button>
                      ) : (
                        <Button className="my-4" color="primary" type="submit">
                          Criar conta
                        </Button>
                      )
                    }
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link
                  className="text-primary"
                  to="/recuperar-senha"
                >
                  Recuperar senha
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link
                  className="text-primary"
                  to='/login'
                >
                  Fazer login
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Cadastro;