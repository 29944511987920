import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import util from '../../services/util';

import Logodoutor from '../../assets/img/logodrclube.png'
import LogodoutorAzul from '../../assets/img/logodrclubeazul.png'

import { Container, Navbar, Nav, NavbarBrand, NavItem, NavLink } from 'reactstrap';

const Header = ({signed}) => {
  const location = useLocation();

  const { estabelecimento } = useSelector((state) => state.estabelecimento);

  if(signed){
    const newURL = 'https://doutor-clube-dev.s3.amazonaws.com/convenios/Avatar+Icon.png';

    return (
      <header className="container-fluid d-flex justify-content-end">
        <div className="d-flex align-items-center">
          <div className="text-right mr-3">
            <span className="b d-block m-0 p-0 text-white">{estabelecimento?.nomeFantasia} - {estabelecimento?.endereco?.endereco}</span>
            <small
              className={`b m-0 p-0 ${estabelecimento.status !== 'Pagamento em dia' && 'text-danger'}`}
            >
              {estabelecimento?.status}
            </small>
          </div>
          <img
            src={estabelecimento?.logo ? `${util.AWS.bucketURL}/${estabelecimento.logo}` : newURL}
            className="avatar"
          />
        </div>
      </header>
    ) 
  }
  else if(location.pathname === '/'){
      return(
        <Navbar expand="lg" navbar="light" fixed="top" className="py-3" data-navbar-on-scroll="data-navbar-on-scroll" style={{ backgroundColor: 'rgba(255,255,255, 0.9)' }}>
        <Container>
        
        <div className="logo">
            <img src={LogodoutorAzul} style={{width: 180}} alt="..."></img>             
        </div>
         
            
        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-1" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto pt-2 pt-lg-0">
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link active text-700 fs-0 fw-medium" aria-current="page" href="#home">Início</a></li>
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="#features">Vantagens</a></li>
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="#pricing">Preço</a></li>
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="#faq">Perguntas</a></li>
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="https://intercom.help/dr-clube/pt-BR">Ajuda</a></li>
              <li className="nav-item ps-lg-4" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="https://atendente.drclube.com">Intranet</a></li>
              <li className="nav-item" data-anchor="data-anchor"><a className="nav-link text-700 fs-0 fw-medium" href="/login">Entrar</a></li>
            </ul>
              
            <a className="ps-lg-3" href="/cadastro">
             <button className="btn btn-lg btn-primary rounded-pill order-0" type="submit">
             Seja parceiro
            </button>
            </a>
            
          </div>
        </Container>
        </Navbar>
      )
    }else{
      return (
        <Navbar expand="lg" light fixed="top" className="py-3" data-navbar-on-scroll="data-navbar-on-scroll">
          <Container>
              <NavbarBrand  className="d-flex align-items-center fw-bold fs-2">
              <div className="logo">
              <img src={Logodoutor} style={{width: 150}} alt="..."></img>             
          </div>
              </NavbarBrand>
              <Nav className="ms-auto pt-2 pt-lg-0">
              <NavItem data-anchor="data-anchor">
                <Link className="nav-link fw-medium text-white" to="/">Início</Link>
              </NavItem>
              </Nav>
              <NavItem data-anchor="data-anchor">
                  <Link className="nav-link fw-medium text-white" to="/login">Entrar</Link>
              </NavItem>
              <NavItem data-anchor="data-anchor">
                  <div className="">
                  <Link className="nav-link fw-medium text-white" to="/cadastro">Criar conta</Link>
                  </div>
              </NavItem>
          </Container>
          </Navbar>
      )
    }
  }

export default Header;